import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import { HTMLContent } from '../components/Content'
import Blurb from '../components/blurb'
import Buffer from '../components/buffer'
import PartnersGallery from '../components/partnersgallery'

export const PartnersTemplate = ({
  title, description, clients,
}) => (
    <>
      <Buffer />
      <Blurb
        title={title}
        body={description}
      />
      <PartnersGallery clients={clients} />
    </>
)

PartnersTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const PartnersPage = ({ data }) => {
  console.log(data)
  const { markdownRemark: post } = data

  return (
    <Layout
      meta={{
        title: 'Partners',
        type: 'article',
        'og:title': post.frontmatter.title,
        'og:description': post.frontmatter.description,
        url: 'https://www.surfncode.io/partners',
      }}
    >
      <PartnersTemplate
        contentComponent={HTMLContent}
        {...post.frontmatter}
        content={post.html}
      />
    </Layout>
  )
}

export default PartnersPage

export const partnersPageQuery = graphql`
  query PartnersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title,
        description
        clients {
            name
            url
            description
            logo {
              childImageSharp {
                fluid(maxWidth: 270, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
        }
      }
    }
  }
`
