import React from 'react'

import Img from 'gatsby-image'

import css from './partnersgallery.sass'

const PartnersGallery = ({ clients = [] }) => {
    console.log(clients);
    return (
        <section className="portfolio-gallery">
            {clients.map(({ name, url, description, logo }) => (
                <a href={url} target="_blank" key={url} className="portfolio-card">
                    <Img className="avatar" fluid={logo.childImageSharp.fluid} style={{ position: 'absolute' }} />
                    <div className="name">{name}</div>
                    {description && <div className="bio">{description}</div>}
                </a>
            ))}
        </section>
    )
}

export default PartnersGallery
